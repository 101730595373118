<nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div style="text-align: justify; white-space: pre-line;">
        {{message}}
      </div>
      <div class="text-center m-t-20">
        <b>{{question}}</b>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="modal-footer">
        <button nbButton matRipple ghost status="warning" (click)="dismiss(false)">{{no}}</button>
        <button nbButton matRipple status="primary" #yesButton id="yesButton" (click)="dismiss(true)">{{yes}}</button>
      </div>
    </nb-card-footer>
</nb-card>