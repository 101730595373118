import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CONST } from '../../const';
import { UtilitiesService } from '../../utils/utils.services';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

export interface UserInfo {
  id: number,
  usuario?: string,
  email?: string,
  tipoUsuario?: string,
  role: string,
  roles?: any[],
  estatus: string,
  fechaContrasena?: Date,
  web_key: string,
}

@Injectable({providedIn: 'root'})
export class UserService {

    constructor(private http: HttpClient, private router: Router,
    private sanitizer: DomSanitizer) {}

    signIn(user: string, password: string) {
      let userCryptAES = UtilitiesService.encryptAES(user);
      const body = {};
      let params = new HttpParams();
      params = params.set('canal', CONST.CANALES.admin.toString());
      return this.http.post(environment.urlApi + '/administradores/'+userCryptAES+'/inicio-sesion', body, { headers: new HttpHeaders({ 'Authorization': 'Basic ' + btoa(userCryptAES + ':' + password) },), params: params , observe: 'response' });
    }

    signOut(is401?: boolean) {
        return this.http.post(environment.urlApi + '/administradores/'+this.getUserInfo().usuario+'/cierre-sesion', {}).toPromise();;
    }

    updatePassword(oldPassword: string, newPassword: string) {
      const body = {
        "contrasenaActual": oldPassword,
        "contrasenaNueva": newPassword,
        "canal": CONST.CANALES.admin
      };
      let params = new HttpParams();
      params = params.set('canal', CONST.CANALES.admin.toString());
      return this.http.post(environment.urlApi + '/usuarios/'+this.getUserInfo().usuario+'/cambio-contrasena', body);
    }


    findUsers(idUserType: string, typeRole: string, filtro: string) {
      let params = new HttpParams();
      params = params.set('canal', CONST.CANALES.admin.toString());
      params = params.set('idUserType', idUserType);
      if(filtro && filtro.trim() != '') {
        params = params.set('filtro', filtro);
      }
      return this.http.get(environment.urlApi + '/usuarios', {params: params}).toPromise();;
    }
    
    findUserById(idUser:number) {
      return this.http.get(environment.urlApi + '/usuarios/'+idUser).toPromise();;
    }

    updateUser(body, idUser: number) {
      return idUser ? this.http.post(environment.urlApi + '/usuarios/'+idUser, body) : this.http.post(environment.urlApi + '/usuarios', body);
    }

    deleteUser(idUser: number) {
      return this.http.delete(environment.urlApi + '/usuarios/'+idUser).toPromise();
    }

    restorePasswordUser(idUser: number) {
      return this.http.post(environment.urlApi + '/usuarios/'+idUser+'/restablecimiento-contrasena', {}).toPromise();
    }
    

    findTypesUser() {
      return this.http.get(environment.urlApi + '/usuarios/tipos-usuarios').toPromise();;
    }

    findTypesRoles(typeUser?: number) {
      let params = new HttpParams();
      if(typeUser) {
        params = params.set('idTipoUsuario', typeUser.toString());
      }
      return this.http.get(environment.urlApi + '/usuarios/roles', {params: params}).toPromise();;
    }

    // getProfileData(role: string) {
    //     return this.http.post(environment.urlApi + '/users/login?to=' + role, {});
    // }

    // getUserRoles(userId) {
    //     return this.http.get(environment.urlApi + '/users/' + userId + '/roles');
    // }

    // getUserRolesStatus(userId) {
    //     return this.http.get(environment.urlApi + '/users/' + userId + '/roles/status');
    // }

    

    // setMainRole(userId: number, role: string) {
    //     return this.http.put(environment.urlApi + '/users/' + userId + '/roles/' + role + '/default', {});
    // }

    // getRolesStatus(userId: number) {
    //     return this.http.get(environment.urlApi + '/users/' + userId + '/roles/status');
    // }

    // changePassword(data: any) {
    //     return this.http.post(environment.urlApi + '/users/password', data);
    // }

    public setDataSession(userinfo: UserInfo, token?: string, email?: string, remember?: boolean) {
        if (token) {
          let tokenEncrypted = UtilitiesService.encryptAES(token);
          sessionStorage.setItem(CONST.LOCALSTORAGE.token, tokenEncrypted);
        }
        if (remember) {
          localStorage.setItem(CONST.LOCALSTORAGE.rememberMe, "true");
        } else {
          localStorage.removeItem(CONST.LOCALSTORAGE.rememberMe);
        }

        // asignar web_key para validar la sesion
        userinfo.web_key = CONST.CRYPTO.web_key;
        let userData = JSON.stringify(userinfo).toString();
        let userDataEncrypted = UtilitiesService.encryptAES(userData);
        sessionStorage.setItem(CONST.LOCALSTORAGE.user, userDataEncrypted);
        email ? localStorage.setItem(CONST.LOCALSTORAGE.email, email) : null;
    }

    public clearSession() {
      let isRememberme = localStorage.getItem(CONST.LOCALSTORAGE.rememberMe);
      sessionStorage.removeItem(CONST.LOCALSTORAGE.token);
      sessionStorage.removeItem(CONST.LOCALSTORAGE.user);
      if (!isRememberme || isRememberme == 'false') {
          localStorage.removeItem(CONST.LOCALSTORAGE.email);
      }
    }

    public getUserInfo(notInvalidate?: boolean): UserInfo | null {
      let encrypterUser = sessionStorage.getItem(CONST.LOCALSTORAGE.user);
      
      if (encrypterUser) {
          let decryptedUser:any = UtilitiesService.decryptAES(encrypterUser);
          let decryptedUserJson:UserInfo = JSON.parse(decryptedUser);
          if (decryptedUser && decryptedUserJson.web_key == CONST.CRYPTO.web_key) {
              return decryptedUserJson;
          } else {
              !notInvalidate ? this.invalidateSession() : null;
              return null;
          }
      } else {
          !notInvalidate ? this.invalidateSession() : null;
          return null;
      }
    }

    async getIdentificationDocumentPic(idAthlete?: number) {
        if (idAthlete) {
            return await this.http.get(environment.urlApi.concat(`/athletes/${idAthlete}/doc-img`), { responseType: 'blob' as 'json' }).toPromise();
        } else {
            return await this.http.get(environment.urlApi.concat('/users/profile/doc-img'), { responseType: 'blob' as 'json' }).toPromise();
        }
    }

    public getToken(): string | null {
      let encrypterToken = sessionStorage.getItem(CONST.LOCALSTORAGE.token);
      if (encrypterToken) {
        let decryptedToken = UtilitiesService.decryptAES(encrypterToken);
        if (decryptedToken != null && this.getUserInfo().web_key == CONST.CRYPTO.web_key){
          return decryptedToken;
        } else {
            return null;
        }
      } else {
          return null;
      }
    }

    private invalidateSession() {
      this.clearSession();
      localStorage.setItem(CONST.LOCALSTORAGE.errorLogin, 'Session Invalidated.');
      this.router.navigateByUrl('/public/home', { replaceUrl: true })
    }

    public convertBlobToBase64(blob: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader;
            reader.onerror = reject;
            reader.onload = () => {
                let url = this.sanitizer.bypassSecurityTrustUrl(reader.result.toString());
                resolve(url);
            };
            reader.readAsDataURL(blob);
        });
    }
    
    // validateTokenRestorePassword(uuid) {
    //   let params = new HttpParams();
    //   params = params.set('uuid', uuid.toString());
    //   return this.http.get(environment.urlApi + '/users/restore/password', {params: params}).toPromise();
    // }

    // tokenRestorePassword(body, uuid?) {
    //   let params = new HttpParams();
    //   if(uuid) {
    //     params = params.set('uuid', uuid.toString());
    //   }
    //   return this.http.put(environment.urlApi + '/users/restore/password', body, {params: params}).toPromise();
    // }

}