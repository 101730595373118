import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  @Input() title: string;
  @Input() message: string;
  @Input() question: string;
  @Input() yes: string;
  @Input() no: string;

  constructor(protected ref: NbDialogRef<ConfirmDialogComponent>) { 
  }

  ngAfterViewInit(): void {
    document.getElementById("yesButton").focus();
  }

  dismiss(value: boolean) {
    this.ref.close(value);
  }
}
