import { Component } from '@angular/core';

@Component({
  selector: 'ngx-no-column-layout',
  styleUrls: ['./no-column.layout.scss'],
  template: `
    <nb-layout class="no-column">
      <nb-layout-column style="padding:0" class="no-column-page">
        <div class="container-animation">
        </div>
      <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class NoColumnLayoutComponent { }
