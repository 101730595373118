import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Observable } from "rxjs-compat";
import { NbToastrService } from "@nebular/theme";
import { UtilitiesService } from "../providers/utils/utils.services";
import { UserService } from "../providers/services/user/user.services";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(
        private userService: UserService,
        private nbToast: NbToastrService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).catch(error => this.handleError(error));
    }

    handleError(errorResponse: HttpErrorResponse): Observable<any> {
        let status = errorResponse.status;
        if (status === 0 || status === 502 || status === 503 || status === 504) {
            UtilitiesService.showToastError(this.nbToast, "Error", "Service not available. Please try again later. ");
        }

        if (status === 401 || status === 400 || status === 500 || status === 404) {
            console.log(errorResponse.error);
            let error = 'Unknown error occurred on the server. Please try again later. ';
            if (errorResponse.error != undefined && errorResponse.error['mensaje']) {
                error = errorResponse.error['mensaje'];
            }

            if(errorResponse.error != undefined && errorResponse.error.code && errorResponse.error.code == 107){
                return Observable.throw(errorResponse);
            }
            
            UtilitiesService.showToastError(this.nbToast, "Error", error);
        }
        return Observable.throw(errorResponse);
    }

}