
const BASIC_CONST = {

  /**
   * Espacio para las constantes de los status
   */

  STATUS_USER_INACTIVE: 0,
  STATUS_USER_ACTIVE: 1,
  STATUS_USER_BLOCKED: 3,

  /**
   * Estatus para las constantes de los roles
   */
  ROLE_ADMIN: 'ADMIN',

  /**
   * canales de comunicación
   */
  CANAL_ADMIN: 4,

  /**
   * Constantes para variables de localstorage
   */
  LOCALSTORAGE_TOKEN: 'token',
  LOCALSTORAGE_USER: 'user',
  LOCALSTORAGE_EMAIL: 'email',
  LOCALSTORAGE_THEME: 'theme',
  LOCALSTORAGE_LANGUAGE: 'language',
  LOCALSTORAGE_INFOSIGNUP: 'infoSignUp',
  LOCALSTORAGE_ERROR_LOGIN: 'error_login',
  LOCALSTORAGE_REMEMBER_ME: 'rmb',


  REGEX_PASSWORD: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,72}$/,
  REGEX_EMAIL: "^[a-zA-Z0-9._%+-]+[a-zA-Z0-9]@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,40}$",

  CRYPTO_AES_SECRET_KEY: 'nShJnDgBKnPhYeJ!',
  CRYPTO_AES_VECTOR_KEY: 'UOLMmSRVMhyLG!IF',
  WEB_KEY: 'UOLMmSRVMhyLG!IF',

}

/** CONFIGURACION DE CONSTANTES */
export const CONST = {
  CANALES : {
    admin: BASIC_CONST.CANAL_ADMIN
  },
  CRYPTO: {
    key: BASIC_CONST.CRYPTO_AES_SECRET_KEY,
    vector: BASIC_CONST.CRYPTO_AES_VECTOR_KEY,
    web_key: BASIC_CONST.WEB_KEY
  },
  STATUS: {
    USER: {
      blocked: BASIC_CONST.STATUS_USER_BLOCKED,
      active: BASIC_CONST.STATUS_USER_ACTIVE,
      inactive: BASIC_CONST.STATUS_USER_INACTIVE,
    }
  },
  ROLES: {
    admin: BASIC_CONST.ROLE_ADMIN,
  },

  LOCALSTORAGE: {
    token: BASIC_CONST.LOCALSTORAGE_TOKEN,
    user: BASIC_CONST.LOCALSTORAGE_USER,
    email: BASIC_CONST.LOCALSTORAGE_EMAIL,
    theme: BASIC_CONST.LOCALSTORAGE_THEME,
    language: BASIC_CONST.LOCALSTORAGE_LANGUAGE,
    infoSignUp: BASIC_CONST.LOCALSTORAGE_INFOSIGNUP,
    errorLogin: BASIC_CONST.LOCALSTORAGE_ERROR_LOGIN,
    rememberMe: BASIC_CONST.LOCALSTORAGE_REMEMBER_ME,
  },

  REGEX: {
    password: BASIC_CONST.REGEX_PASSWORD,
    email: BASIC_CONST.REGEX_EMAIL
  },

 
};
