import { EventEmitter, Injectable } from "@angular/core";
import { NbComponentStatus, NbToastrService, NbGlobalPhysicalPosition, NbDialogService, NbIconConfig, NbGlobalLogicalPosition } from '@nebular/theme';
import { BigNumber} from "bignumber.js";
import * as crypto from 'crypto-js';
import { CONST } from "../const";
import * as deepEqual from "deep-equal";
import { MessageDialogComponent } from "./dialog-overlays/message-dialog/message-dialog.component";
import { ConfirmDialogComponent } from "./dialog-overlays/confirm_dialog/confirm-dialog.component";

export interface Column {
    id: string;
    name?: string;
    displayable?: boolean;
    update?: boolean;
}

@Injectable()
export class UtilitiesService {
    public validateProfileSideMenu: EventEmitter<any> = new EventEmitter<any>();
    public validateProfilePicture: EventEmitter<any> = new EventEmitter<any>();
    public validateProfileName: EventEmitter<any> = new EventEmitter<any>();
    public validateRoles: EventEmitter<any> = new EventEmitter<any>();

    public static tiempoDespliegue = 5000;

    public static encryptAES(plainText: string): string {

        // Crear la clave y el IV a partir de las cadenas UTF8
        const key = crypto.enc.Utf8.parse(CONST.CRYPTO.key);
        const iv = crypto.enc.Utf8.parse(CONST.CRYPTO.vector);
    
        // Configuración de opciones de cifrado para AES CBC y PKCS7 padding
        const options = {
          mode: crypto.mode.CBC,
          padding: crypto.pad.Pkcs7,
          iv: iv
        };
        // Cifrado del texto
        const encrypted = crypto.AES.encrypt(plainText, key, options);
        // Convertir a cadena base64 y reemplazar '/' por '_'
        const base64String = encrypted.toString().replace(/\//g, '_');
        return base64String;
    }


    public static decryptAES(encryptedText: string): any {
        // Crear la clave y el IV a partir de las cadenas UTF8
        let key = crypto.enc.Utf8.parse(CONST.CRYPTO.key);
        let iv = crypto.enc.Utf8.parse(CONST.CRYPTO.vector);
        
        // Configuración de opciones de descifrado para AES CBC y PKCS7 padding
        let options = {
          mode: crypto.mode.CBC,
          padding: crypto.pad.Pkcs7,
          iv: iv
        };
    
        // Reemplazar '_' por '/' para que la cadena base64 sea válida
        encryptedText = encryptedText.replace(/_/g, '/');

        // Descifrado del texto
        let decrypted = crypto.AES.decrypt(encryptedText, key, options);
    
        // Convertir el resultado descifrado a una cadena UTF8
        return decrypted.toString(crypto.enc.Utf8);

    }
    
    
    // public static encrypt(data: any) {
    //     let encryptData = {
    //         validator: CONST.CRYPTO.key,
    //         data: data
    //     }
    //     return crypto.AES.encrypt(JSON.stringify(encryptData), this.secretKey.trim()).toString();
    // }

    // public static decrypt(textToDecrypt: string) {
    //     let decryptedData = null
    //     try {
    //         decryptedData = JSON.parse(crypto.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(crypto.enc.Utf8));
    //     } catch (e) {
    //         decryptedData = null;
    //     }
    //     return decryptedData
    // }

    public static showToast(toastrService: NbToastrService,
        title: string,
        body: string,
        type?: NbComponentStatus,
        duration?: number,
        position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition,
        iconConfig?: NbIconConfig) {
        let defaultIconConfig: NbIconConfig = { icon: 'comment-alt', pack: 'fa' };

        const config: any = {
          status: type ? type : "basic",
          duration: duration ? duration : duration == 0 ? 0 : 3000,
          position: position ? position : NbGlobalPhysicalPosition.TOP_RIGHT,
          destroyByClick: true,
          preventDuplicates: true,
          hasIcon: true,
          icon: iconConfig ? iconConfig : defaultIconConfig,
        };

        const titleContent = title ? title : '';

        toastrService.show(body, `${titleContent}`, config);
    }

    public static showToastPrimary(toastrService: NbToastrService,
        title: string,
        body: string, duration?: number, position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition) {
        let time = duration || duration == 0 ? duration : this.tiempoDespliegue;
        this.showToast(toastrService, title, body, 'primary', time, position)
    }

    public static showToastSuccess(toastrService: NbToastrService,
        title: string,
        body: string, duration?: number, position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition) {
        let iconConfig: NbIconConfig = { icon: 'check', pack: 'fa' }
        let time = duration || duration == 0 ? duration : this.tiempoDespliegue;
        this.showToast(toastrService, title, body, 'success', time, position, iconConfig)
    }

    public static showToastError(toastrService: NbToastrService,
        title: string,
        body: string, duration?: number, position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition) {
        let iconConfig: NbIconConfig = { icon: 'exclamation-circle', pack: 'fa' }
        let time = duration || duration == 0 ? duration : 0;
        this.showToast(toastrService, title, body, 'danger', time, position, iconConfig)
    }

    public static showToastInfo(toastrService: NbToastrService,
        title: string,
        body: string, duration?: number, position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition) {
        let iconConfig: NbIconConfig = { icon: 'info-circle', pack: 'fa' }
        let time = duration || duration == 0 ? duration : this.tiempoDespliegue;
        this.showToast(toastrService, title, body, 'info', time, position, iconConfig)
    }

    public static showToastWarning(toastrService: NbToastrService,
        title: string,
        body: string, duration?: number, position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition) {
        let iconConfig: NbIconConfig = { icon: 'exclamation-triangle', pack: 'fa' }
        let time = duration || duration == 0 ? duration : this.tiempoDespliegue;
        this.showToast(toastrService, title, body, 'warning', time, position, iconConfig)
    }

    public static showToastControl(toastrService: NbToastrService,
        title: string,
        body: string, duration?: number, position?: NbGlobalPhysicalPosition | NbGlobalLogicalPosition) {
        let iconConfig: NbIconConfig = { icon: 'exclamation-triangle', pack: 'fa' }
        let time = duration || duration == 0 ? duration : this.tiempoDespliegue;
        this.showToast(toastrService, title, body, 'control', time, position, iconConfig)
    }


    public static showConfirmDialog(dialogService: NbDialogService, title: string, message: string, question?: string, yesOption?: string, noOption?: string) {
        return new Promise(resolve => {
            dialogService.open(ConfirmDialogComponent, {
                context: {
                    title: title,
                    message: message,
                    question: question ? question : null,
                    yes: yesOption ? yesOption : "Aceptar",
                    no: noOption ? noOption : 'Cancelar'
                },
            }).onClose.subscribe(res => {
                resolve(res);
            });
        });
    }

    // public static showConfirmRoleDialog(dialogService: NbDialogService, role: string) {
    //     // return new Promise(resolve => {
    //     //     dialogService.open(ConfirmRoleDialogComponent, {
    //     //         context: {
    //     //             role: role
    //     //         },
    //     //     }).onClose.subscribe(res => {
    //     //         resolve(res);
    //     //     });
    //     // });
    // }

    // public static showChangePasswordDialog(dialogService: NbDialogService) {
    //     return new Promise(resolve => {
    //         dialogService.open(ChangePasswordDialogComponent).onClose.subscribe(res => {
    //             resolve(res);
    //         });
    //     });
    // }

    // public static showImgPicker(dialogService: NbDialogService, options: ImgCropperOptions) {
    //     return new Promise(resolve => {
    //         dialogService.open(ImgPickerComponent, {
    //             context: {
    //                 options: options
    //             },
    //         }).onClose.subscribe(res => {
    //             resolve(res);
    //         });
    //     });
    // }

    public static showMessageDialog(dialogService: NbDialogService, title: string, message: string, okOption?: string) {
        return new Promise(resolve => {
            dialogService.open(MessageDialogComponent, {
                context: {
                    title: title,
                    message: message,
                    ok: okOption ? okOption : "Aceptar",
                },
            }).onClose.subscribe(res => {
                resolve(res);
            });
        });
    }

  
}