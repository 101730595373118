import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs-compat";
import { CONST } from "../providers/const";
import { UserService } from "../providers/services/user/user.services";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('/inicio-sesion')) {
      return next.handle(request);
    }

    var token: any = this.userService.getToken();

    if (token) {
      request = request.clone({
        setHeaders: {
          'X-Auth-Token': token
        }
      });
    }

    return next.handle(request).catch(error => this.handleError(error));
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      sessionStorage.removeItem(CONST.LOCALSTORAGE.token);
      sessionStorage.removeItem(CONST.LOCALSTORAGE.user);
      localStorage.setItem(CONST.LOCALSTORAGE.errorLogin, (error.error && error.error.message ? error.error.message : 'Session Invalidated'))
      this.router.navigate(['/public/login']);
      //   return Observable.of(error.message);
    }

    if (error.status === 403) {
      sessionStorage.removeItem(CONST.LOCALSTORAGE.token);
      sessionStorage.removeItem(CONST.LOCALSTORAGE.user);
      localStorage.setItem(CONST.LOCALSTORAGE.errorLogin, (error.error && error.error.message ? error.error.message : 'Session Invalidated'))
      this.router.navigate(['/public/login']);
      //   return Observable.of(error.message);
    }

    return Observable.throw(error);
  }

}