<nb-card>
    <nb-card-header>{{ title }}</nb-card-header>
    <nb-card-body>
      <div style="text-align: justify; white-space: pre-line;">
        {{message}}
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="modal-footer">
        <button nbButton matRipple status="primary" (click)="dismiss()">{{ok}}</button>
      </div>
    </nb-card-footer>
</nb-card>