import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../../../providers/services/user/user.services';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'material-light',
      name: 'Material Light',
    },
    {
      value: 'material-dark',
      name: 'Material Dark',
    },
  ];

  currentTheme = 'default';

  public MENU_SIGNOUT = 'Cerrar Sesión';
  public MENU_UPDATE_PASSWORD = 'Actualizar Contraseña';

  public isLoadingSignOut = false;
  userMenu = [ { title: this.MENU_UPDATE_PASSWORD }, { title: this.MENU_SIGNOUT } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userDataService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private router: Router,
              private userService: UserService) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    this.userDataService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'header-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        this.onItemSelection(title);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onContecxtItemSelection(title) {
    console.log('click', title);
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onItemSelection(title) {
    console.log('click', title);
    if(this.isLoadingSignOut) return;
    switch (title) {
      // case this.userMenuProfile:
      //   this.router.navigate(['/portal/profile']);
      //   break;
      case this.MENU_UPDATE_PASSWORD:
        this.router.navigate(['/home/profile/update-password']);
        break;
      case this.MENU_SIGNOUT:
        this.isLoadingSignOut = true;
        this.userService.signOut()
          .finally(() => {
            this.isLoadingSignOut = false;
            this.userService.clearSession();
            this.router.navigate(['/public']);
          })
          .then(() => { });
        break;

    }
  }
}
